.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.loading-spinner {
    margin-left: 1rem;
    margin-right: 1rem;
}