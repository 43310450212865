.jumbotron-tagline-header {
    font-weight: 400;
    font-size: 2.25rem;
    padding-bottom: 1rem;
}

.jumbotron-tagline-header-container {
    margin-bottom: 2%;
}

.Typewriter {
    font-weight: 400;
    font-size: 1.25rem;
    padding-left: 0.25rem;
    display: inline-block;
}

.jumbotron-tagline-typewriter-container {
    margin-bottom: 5%;
}

.jumbotron-tagline-pre_typewriter {
    font-size: 1.25rem;
    font-weight: 200;
}

.jumbotron-tagline-cta-btn-container {
    max-width: fit-content;
    margin-top: 1rem;
}

@media only screen and (max-width: 576px) {
    .jumbotron-tagline-header-container {
        margin-bottom: 10%;
    }
    
    .jumbotron-tagline-typewriter-container {
        margin-bottom: 15%;
    }

    .Typewriter {
        padding-top: 0.5rem;
        padding-left: 0;
        display: block;
    }
    
    .jumbotron-tagline-cta-btn {
        margin-bottom: 3%;
    }

    .jumbotron-tagline-cta-btn-container {
        padding-top: 5%;
    }
}