.footer-container {
    background-color: #212529;
    min-height: 40vh;
    width: auto;
    margin-top: 5rem;
    padding-top: 3rem;
}

.footer-about-col {
    padding-left: 1.5rem;
    max-width: 400px;
}

.left-most-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-about-header {
    font-weight: 600;
    font-size: 1.125em;
}

.footer-about-content {
    font-weight: 100;
    font-size: 1em;
}

.footer-about-copyright {
    margin-top: 2rem;
    font-weight: 100;
}

@media only screen and (max-width: 576px) {
    .footer-about-col {
        padding-right: 3rem;
        white-space: wrap;
    }
    
    .footer-contact-cta-btn {
        margin-top: 1%;
    }
    
    .footer-social-link-container {
        margin-top: 2%;
        margin-bottom: 3%;
    }
}

@media only screen and (min-width: 768px) {
    .footer-inner-container {
        flex-wrap: nowrap;
    }
    
    .footer-social-link-container {
        margin-top: 2%;
        margin-bottom: 3%;
    }
}

.footer-contact-inner-container {
    margin-bottom: 10%;
}

.footer-contact-header {
    font-weight: 600;
    font-size: 1em;
}

.footer-contact-content {
    font-weight: 100;
    font-size: 0.875em;
    color: var(--main-txt);
}

.footer-contact-cta-btn {
    font-size: 0.875em;
    max-width: fit-content;
}

.footer-contact-email-link {
    color: var(--main-txt);
    
}

.footer-socials-img {
    width: 1em;
}