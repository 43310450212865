.section-container {
    padding-top: 2rem;
}

.section-body-header-container {
    margin-bottom: 0.5rem;
    margin-left: 0 !important;
}

.section-body-text {
    margin-bottom: 1rem;
}

.section-body-cta-btn {
    margin-right: 1rem;
}

.section-img-container {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.section-img {
    /* max-width: 200px; */
    width: 100%;
    object-fit: contain;
}

.section-built-with-text {
    font-weight: 600;
    font-size: 0.75em;
}

.section-built-with-icon {
    height: 64px;
    /* width: 64px; */
    padding: 0.25rem;
}

.section-built-with-icon-container {
    margin-left: -0.25rem;
    display: flex;
    justify-content: space-around;
}

.section-bottom-row-container {
    align-items: center;
    margin-top: 0.5rem;
}

@media only screen and (max-width: 425px) {
    .section-container {
        height: fit-content;
        margin-bottom: 1rem;
    }

    .section-img {
        /* max-width: 90%; */
    }
    
    .section-bottom-row-container {
        flex-direction: column-reverse;
        margin-top: 0;
    }
    
    .section-body-cta-btn-container {
        margin-top: 1.5rem;
    }

    .section-body-cta-btn {
        margin-bottom: 0.75rem;
    }

    .section-built-with-icon-container, .section-img-container {
        float: none!important;
    }

    .section-built-with-text {
        padding-top: 1rem;
        margin-bottom: 1rem;

    }
}