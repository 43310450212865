.about-header-container {
    margin-top: 2rem;
}

.about-preamble-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #3f3f3f;
    width: 100vw;
}

.text-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}