.pl-container {
    /* height: 80vh; */
}

.pl-content-container {
    height: 90vh;
    width: 80%;
}

.pl-content-header {
    font-size: 2.25rem;
    margin-bottom: 1rem;
}

.pl-cta-btn-container {
    max-width: fit-content;
    margin-top: 2rem;
}

.pl-cta-btn {
    margin-right: 1rem;
}

@media only screen and (max-width: 320px) {
    .pl-cta-btn {
        margin-bottom: 1rem;
    }
}
