.jumbotron-container {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.jumbotron-row-container {
    flex-direction: column;
    justify-content: center;
}

.jumbotron-img-container {
    justify-content: center;
    align-items: center;
    width: 80%;
}

.jumbotron-img {
    object-fit: contain;
}

@media only screen and (max-width: 576px) {
    .jumbotron-img-container {
        justify-content: center;
        align-items: center;
        width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    .jumbotron-container {
        height: 90vh;
    }
}

@media only screen and (max-width: 1024px) {
    .jumbotron-container {
        height: 92vh;
    }
}

@media only screen and (max-width: 1440px) {
    .jumbotron-container {
        height: 95vh;
    }
}

@media only screen and (max-width: 2560px) {
    .jumbotron-container {
        height: 92vh;
    }
}