.not-found-container {
    margin-top: 2rem;
    flex-direction: column;
    align-items: center;
}

.not-found-header {
    margin-bottom: 2rem;
}

.not-found-img-container {
    margin-bottom: 2rem;
}

.not-found-img {
    object-fit: contain;
    max-width: 200px;
}