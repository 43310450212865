.cta-btn {
    border-radius: 2rem;
    white-space: nowrap;
}

.cta-btn-container {
    width: fit-content;
}

.cta-btn-outline-primary {
    border-color: var(--primary);
    color: var(--primary);
}

.cta-btn-primary {
    background-color: var(--primary);
    color: var(--main-txt);
}

.cta-btn-linkedin {
    background-color: #0172B1;
    border-color: #0172B1;
    color: var(--main-txt);
}

.cta-btn-outline-primary:hover, .cta-btn-outline-primary:focus, .cta-btn-outline-primary:active {
    background-color: var(--primary);
    color: var(--main-txt);
}

.cta-btn-outline-secondary {
    border-color: var(--secondary);
    color: var(--secondary);
}

.cta-btn-outline-secondary:hover, .cta-btn-outline-secondary:focus, .cta-btn-outline-secondary:active {
    background-color: var(--secondary);
    color: var(--main-txt);
}

.cta-btn-outline-source {
    border-color: var(--main-txt);
    color: var(--main-txt);
}

.cta-btn-outline-source:hover, .cta-btn-outline-source:focus, .cta-btn-outline-source:active {
    background-color: var(--main-txt);
    border-color: var(--main-txt);
    color: var(--main-bg);
}

.cta-btn-outline-linkedin {
    border-color: #0172B1;
    color: #0172B1;
}

.cta-btn-outline-linkedin:hover, .cta-btn-outline-linkedin:focus, .cta-btn-outline-linkedin:active {
    background-color: #0172B1;
    border-color: #0172B1;
    color: var(--main-txt);
}

