.header-underlined-sm {
    font-size: 1.5rem;
    font-weight: 500;
    border-bottom: 3px solid var(--primary);
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0.75rem;
    margin-bottom: 1rem;
    width: fit-content;
}

.header-underlined-md {
    font-size: 2rem;
    font-weight: 500;
    border-bottom: 4px solid var(--primary);
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0.75rem;
    margin-bottom: 1rem;
    width: fit-content;
}

.header-underlined-lg {
    font-size: 2.25rem;
    font-weight: 600;
    border-bottom: 5px solid var(--primary);
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0.75rem;
    margin-bottom: 2rem;
    width: fit-content;
}
