.addsec-container {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.addsec-content-container {
    margin-bottom: 1rem;
}

li {
    margin-bottom: 0.5rem;
}